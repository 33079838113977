<template>
  <div class="app-page-toolbar">
    <router-link
      :to="{ path: `/support-requests/${record.id}/edit` }"
      v-if="record && hasPermissionToEdit"
    >
      <el-button icon="el-icon-fa-edit" type="primary" :class="rtlAndPadding">
       <span class="card-title"> <app-i18n code="common.edit"></app-i18n> </span>
      </el-button>
    </router-link>

    <el-button
      :disabled="destroyLoading"
      @click="doDestroyWithConfirm"
      icon="el-icon-fa-trash"
      type="primary"
      :class="rtlAndPadding"
      v-if="record && hasPermissionToDestroy"
    >
     <span class="card-title"> <app-i18n code="common.destroy"></app-i18n> </span>
    </el-button>

    <router-link
      :to="{
        path: '/audit-logs',
        query: { entityId: record.id },
      }"
      v-if="record && hasPermissionToAuditLogs"
    >
      <el-button icon="el-icon-fa-history" :class="rtlAndPadding">
       <span class="card-title"> <app-i18n code="auditLog.menu"></app-i18n> </span>
      </el-button>
    </router-link>
  </div>
</template>

<script>
import { SupportRequestsPermissions } from '@/modules/support-requests/support-requests-permissions';
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'app-support-requests-view-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      record: 'supportRequests/view/record',
      loading: 'supportRequests/view/loading',
      destroyLoading: 'supportRequests/destroy/loading',
      rtlAndPadding: 'supportRequests/form/rtlAndPadding'
    }),

    hasPermissionToEdit() {
      return new SupportRequestsPermissions(
        this.currentUser,
      ).edit;
    },

    hasPermissionToImport() {
      return new SupportRequestsPermissions(
        this.currentUser,
      ).import;
    },

    hasPermissionToDestroy() {
      return new SupportRequestsPermissions(
        this.currentUser,
      ).destroy;
    },

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },
  },

  methods: {
    ...mapActions({
      doDestroy: 'supportRequests/destroy/doDestroy',
    }),

    async doDestroyWithConfirm() {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroy(this.record.id);
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
