<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/support-requests' }">
        <app-i18n code="entities.supportRequests.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.supportRequests.view.title"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.supportRequests.view.title"></app-i18n>
      </h1>

      <div class="app-page-spinner" v-if="loading" v-loading="loading"></div>

      <app-support-requests-view-toolbar
        v-if="record"
      ></app-support-requests-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-text
          :label="fields.id.label"
          :value="presenter(record, 'id')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.type.label"
          :value="presenter(record, 'type')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.message.label"
          :value="presenter(record, 'message')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.quality.label"
          v-color="{ evaluation: presenter(record, 'quality') }"
          :value="checkValue(presenter(record, 'quality'))"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.difficulty.label"
          v-color="{ evaluation: presenter(record, 'difficulty') }"
          :value="checkValue(presenter(record, 'difficulty'))"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.examname.label"
          :value="presenter(record, 'examname')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.username.label"
          :value="presenter(record, 'username')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.useremail.label"
          :value="presenter(record, 'useremail')"
        ></app-view-item-text>

        <app-view-item-image
          :label="fields.images.label"
          :value="presenter(record, 'images')"
        ></app-view-item-image>

        <app-view-item-text
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SupportRequestsViewToolbar from '@/modules/support-requests/components/support-requests-view-toolbar.vue';
import { SupportRequestsModel } from '@/modules/support-requests/support-requests-model';

const { fields } = SupportRequestsModel;

export default {
  name: 'app-support-requests-view-page',

  props: ['id'],

  components: {
    [SupportRequestsViewToolbar.name]: SupportRequestsViewToolbar
  },

  data() {
    return {
      evaluationColors: ['red', 'red', 'orange', 'blue', 'green'],
      comments: [
        'examname',
        'username',
        'useremail',
        'quality',
        'difficulty',
        'message'
      ]
    };
  },

  directives: {
    color: {
      bind(el, binding, vnode) {
        const evaluation = binding.value.evaluation;
        if (evaluation && evaluation != -1) {
          el.style.color = vnode.context.evaluationColors[evaluation];
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'supportRequests/view/record',
      loading: 'supportRequests/view/loading'
    }),

    fields() {
      return fields;
    }
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'supportRequests/view/doFind'
    }),

    checkValue(value) {
      if (value) {
        if (value != -1) return value + 1 + '/5';
        else return '---';
      } else {
        return '';
      }
    },

    getMessageData(message, mData) {
      let value = JSON.parse(message)[mData];
      return value;
    },

    presenter(record, fieldName) {
      if (record.type == 'Comment') {
        if (this.comments.indexOf(fieldName) > -1) {
          return this.getMessageData(record.message, fieldName);
        } else {
          return record[fieldName];
        }
      } else {
        return SupportRequestsModel.presenter(record, fieldName);
      }
    }
  }
};
</script>
